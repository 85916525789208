import { LitElement, html, css } from "lit";
import { classMap } from "lit/directives/class-map";

import shared from "../../../../shared/styles.scss";
import custom from "./hotspot-create.scss";

import "../../../../custom-uimc/custom-textfield/custom-textfield";
import "@shs/ui-marcom/src/js/toggle-switch";
import "@shs/ui-marcom/src/js/card";

class HotspotCreate extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      zoomLevel: { type: Number },
      zoomItem: { type: Object },
      type: { type: String },
      autoCreateContentPage: { type: Boolean },
      onSelect: { type: Object },
      onInput: { type: Object },
      onAnimationSelect: { type: Object },
    };
  }

  constructor() {
    super();

    this.type = null;
    this.autoCreateContentPage = false;
    this.onSelect = () => {};
    this.onInput = () => {};
    this.onAnimationSelect = () => {};
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  firstUpdated() {
    if (
      this.zoomLevel === 1 &&
      this.project.get("boothTemplate").type === "booth"
    ) {
      this.select("zoom");
    }
  }

  get animationItems() {
    return this.zoomItem?.animationController.map(ac => {
      const animation = {
        name: ac.name,
        value: ac.name,
      };
      return animation;
    });
  }

  get exhibitAnimations() {
    return this.project.exhibits[0].animationController.map(ac => {
      const animation = {
        name: ac.name,
        value: ac.name,
      };
      return animation;
    });
  }

  select(type) {
    this.type = type;
    this.onSelect(type);
  }

  setAnimation(name) {
    this.onAnimationSelect(name);
  }

  render() {
    return html`
      <div class="hotspot-create">
        <div class="row">
          <div class="col-12">
            <p>
              Please select a content type for the hotspot you want to add to
              the 3D booth. Hotspots with zoom will let you add additional
              hotspots with content on a second level.
            </p>
          </div>
        </div>
        <div class="row">
          ${this.zoomLevel === 1 &&
          this.project.get("boothTemplate").type === "booth"
            ? html`
                <div class="col-12 col-md-4 col-xl-3">
                  <uimc-card
                    class="display-block cursor-pointer"
                    @click=${() => this.select("zoom")}
                  >
                    <uimc-aspect-ratio
                      slot="card-image"
                      formats='{"sm": "16x9"}'
                    >
                      <img
                        alt=""
                        src="${this.basePath}assets/images/zoom.png"
                      />
                    </uimc-aspect-ratio>
                    <div class="display-flex justify-content-between">
                      <p class="margin-bottom-0 margin-right-1">
                        <strong>Zoom in on an exhibit</strong>
                      </p>
                      <span
                        class="${classMap({
                          invisible: this.type !== "zoom",
                        })}"
                      >
                        <i class="icon-check icon--success"></i>
                      </span>
                    </div>
                  </uimc-card>
                </div>
              `
            : html`
                <div class="col-12 col-md-4 col-xl-3">
                  <uimc-card
                    class="display-block cursor-pointer"
                    @click=${() => this.select("tooltip")}
                  >
                    <uimc-aspect-ratio
                      slot="card-image"
                      formats='{"sm": "16x9"}'
                    >
                      <img
                        alt=""
                        src="${this.basePath}assets/images/tooltip.png"
                      />
                    </uimc-aspect-ratio>
                    <div class="display-flex justify-content-between">
                      <p class="margin-bottom-0 margin-right-1">
                        <strong>Tooltip with text content</strong>
                      </p>
                      <span
                        class="${classMap({
                          invisible: this.type !== "tooltip",
                        })}"
                      >
                        <i class="icon-check icon--success"></i>
                      </span>
                    </div>
                  </uimc-card>
                </div>

                <div class="col-12 col-md-4 col-xl-3">
                  <uimc-card
                    class="display-block cursor-pointer"
                    @click=${() => this.select("small")}
                  >
                    <uimc-aspect-ratio
                      slot="card-image"
                      formats='{"sm": "16x9"}'
                    >
                      <img
                        alt=""
                        src="${this.basePath}assets/images/small-overlay.png"
                      />
                    </uimc-aspect-ratio>
                    <div class="display-flex justify-content-between">
                      <p class="margin-bottom-0 margin-right-1">
                        <strong>Small overlay with one widget</strong>
                      </p>
                      <span
                        class="${classMap({
                          invisible: this.type !== "small",
                        })}"
                      >
                        <i class="icon-check icon--success"></i>
                      </span>
                    </div>
                  </uimc-card>
                </div>

                <div class="col-12 col-md-4 col-xl-3">
                  <uimc-card
                    class="display-block cursor-pointer"
                    @click=${() => this.select("large")}
                  >
                    <uimc-aspect-ratio
                      slot="card-image"
                      formats='{"sm": "16x9"}'
                    >
                      <img
                        alt=""
                        src="${this.basePath}assets/images/large-overlay.png"
                      />
                    </uimc-aspect-ratio>
                    <div class="display-flex justify-content-between">
                      <p class="margin-bottom-0 margin-right-1">
                        <strong>Large overlay with two or more widgets</strong>
                      </p>
                      <span
                        class="${classMap({
                          invisible: this.type !== "large",
                        })}"
                      >
                        <i class="icon-check icon--success"></i>
                      </span>
                    </div>
                  </uimc-card>
                </div>

                ${this.zoomItem?.animationController?.length > 0 ||
                (this.project.get("boothTemplate").type === "standalone" &&
                  this.project.exhibits[0].animationController?.length > 0) ||
                (this.project.get("boothTemplate").type === "environment" &&
                  this.project.exhibits[0].animationController?.length > 0)
                  ? html`
                      <div class="col-12 col-md-4 col-xl-3">
                        <uimc-card
                          class="display-block cursor-pointer"
                          @click=${() => this.select("animation")}
                        >
                          <uimc-aspect-ratio
                            slot="card-image"
                            formats='{"sm": "16x9"}'
                          >
                            <img
                              alt=""
                              src="${this
                                .basePath}assets/images/animation-hotspot.jpg"
                            />
                          </uimc-aspect-ratio>
                          <div class="display-flex justify-content-between">
                            <p class="margin-bottom-0 margin-right-1">
                              <strong>Starts animation in exhibit</strong>
                            </p>
                            <span
                              class="${classMap({
                                invisible: this.type !== "animation",
                              })}"
                            >
                              <i class="icon-check icon--success"></i>
                            </span>
                          </div>
                        </uimc-card>
                      </div>
                    `
                  : null}
              `}
        </div>

        <div class="row margin-top-1">
          <div class="col-12">
            <p>
              <custom-textfield
                label="Hotspot label"
                .onChange=${label => {
                  this.onInput("label", label);
                }}
              ></custom-textfield>
            </p>

            ${this.type === "tooltip"
              ? html`
                  <p>
                    <custom-textfield
                      label="Hotspot tooltip"
                      .onChange=${tooltip => {
                        this.onInput("tooltip", tooltip);
                      }}
                    ></custom-textfield>
                  </p>
                `
              : null}
            ${this.type === "large" || this.type === "small"
              ? html`
                  ${!this.autoCreateContentPage
                    ? html`
                        <p>
                          <custom-textfield
                            label="Scrivito link"
                            .onChange=${link => {
                              this.onInput("link", link);
                            }}
                          ></custom-textfield>
                        </p>
                        <p>
                          <custom-textfield
                            label="Scrivito editing link"
                            .onChange=${link => {
                              this.onInput("editingUrl", link);
                            }}
                          ></custom-textfield>
                        </p>
                      `
                    : null}
                `
              : null}
            <div class="margin-bottom-2">
              ${this.zoomItem?.animationController?.length > 0
                ? html`
                    <custom-select
                      label="Animations"
                      .onChange=${animationValue => {
                        this.setAnimation(animationValue);
                      }}
                      .items="${[...this.animationItems]}"
                    >
                    </custom-select>
                  `
                : null}
              ${(this.project.get("boothTemplate").type === "standalone" &&
                this.project.exhibits[0].animationController?.length > 0) ||
              (this.project.get("boothTemplate").type === "environment" &&
                this.project.exhibits[0].animationController?.length > 0)
                ? html`
                    <custom-select
                      label="Animations"
                      .onChange=${animationValue => {
                        this.setAnimation(animationValue);
                      }}
                      .items="${[...this.exhibitAnimations]}"
                    >
                    </custom-select>
                  `
                : null}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define("hotspot-create", HotspotCreate);
