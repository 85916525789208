import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js/dist/tween.umd";
import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";

import "./components/hotspot-container/hotspot-container";

import ApiModel from "../Models/ApiModel";

let experience = null;
let camera = null;
let cssScene = null;
let raycaster = null;
let boothTemplate = null;

const DOTWIDTH = 80;

export default class Hotspot extends ApiModel {
  constructor(
    exhibitsHotspot,
    exhibit = null,
    projectExperience = null,
    autoCreateContentPage
  ) {
    super(exhibitsHotspot);

    if (projectExperience) {
      experience = projectExperience;
      ({ camera } = experience);
      ({ cssScene } = experience);
      ({ boothTemplate } = experience.project);
      ({ raycaster } = experience);
    }

    this.exhibit = exhibit;
    this.hotspotGroup = null;

    this.autoCreateContentPage = autoCreateContentPage;
  }

  init() {
    ({ experience } = this.exhibit.exhibition);
    ({ camera } = experience);
    ({ cssScene } = experience);
    ({ boothTemplate } = experience.project);
    ({ raycaster } = experience);

    if (!this.hotspotGroup) {
      this.createContainer();
    }
    this.update();
    this.tempMoveX = this.distanceXFromExhibitPlace;
    this.tempMoveZ = this.distanceZFromExhibitPlace;
  }

  get isPlatform() {
    return experience.project.boothTemplate.type === "platform";
  }

  get boothTemplateOrigin() {
    return boothTemplate.exhibits.find(
      exhibitPlace => exhibitPlace.name === this.exhibit.placeName
    );
  }

  get exhibitPlacePosition() {
    let placeposition;
    // except for booth always look forward in global coord (-z)
    if (this.isPlatform) {
      placeposition = [0, 0, -1];
    } else {
      placeposition = this.boothTemplateOrigin?.position;
    }

    return placeposition;
  }

  get exhibitPositionYCorrection() {
    // exhibitplace position set to y level of hotspot
    const exhibitposition = new THREE.Vector3(...this.exhibitPlacePosition);
    exhibitposition.y = +this.get("position")[1];
    return exhibitposition;
  }

  get directionZ() {
    const origin = new THREE.Vector3(0, +this.get("position")[1], 0);

    // get normalized direction vector between origin and exhibitplace
    const directionVector = new THREE.Vector3();
    return directionVector
      .subVectors(this.exhibitPositionYCorrection, origin)
      .normalize()
      .clone();
  }

  get directionX() {
    // rotate y to get direction x from exhibitplace
    return this.directionZ
      .applyAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI * 0.5)
      .clone();
  }

  moveOnXAxis(value) {
    this.tempMoveX = value;

    // translating via direction by distance from exhibitpalce
    const tempObject3D = new THREE.Object3D();
    tempObject3D.position.copy(this.exhibitPositionYCorrection);
    if (this.tempMoveZ) {
      tempObject3D.translateOnAxis(this.directionZ, this.tempMoveZ);
    }
    tempObject3D.translateOnAxis(this.directionX, value);

    this.setPosition([
      tempObject3D.position.x,
      tempObject3D.position.y,
      tempObject3D.position.z,
    ]);
  }

  moveOnZAxis(value) {
    this.tempMoveZ = value;

    // translating via direction by distance from exhibitpalce
    const tempObject3D = new THREE.Object3D();
    tempObject3D.position.copy(this.exhibitPositionYCorrection);
    if (this.tempMoveX) {
      tempObject3D.translateOnAxis(this.directionX, this.tempMoveX);
    }
    tempObject3D.translateOnAxis(this.directionZ, value);

    this.setPosition([
      tempObject3D.position.x,
      tempObject3D.position.y,
      tempObject3D.position.z,
    ]);
  }

  moveOnYAxis(value) {
    const tempPosition = this.get("position");

    tempPosition[1] = +value;
    this.setPosition(tempPosition);
  }

  get distanceZFromExhibitPlace() {
    const origin = this.exhibitPositionYCorrection.clone();
    const position = new THREE.Vector3(...this.get("position"));

    // 2D distance
    origin.x = position.x;
    origin.y = position.y;

    const absDistance = Math.round(position.distanceTo(origin) / 10) * 10;

    return this.exhibitPositionYCorrection.z > this.get("position")[2]
      ? absDistance
      : 0 - absDistance;
  }

  get distanceXFromExhibitPlace() {
    const origin = this.exhibitPositionYCorrection.clone();
    const position = new THREE.Vector3(...this.get("position"));

    // 2D distance
    origin.z = position.z;
    origin.y = position.y;

    const absDistance = Math.round(position.distanceTo(origin) / 10) * 10;

    return this.exhibitPositionYCorrection.x < this.get("position")[0]
      ? absDistance
      : 0 - absDistance;
  }

  get distanceYFromExhibitPlace() {
    const origin = new THREE.Vector3(...this.exhibitPlacePosition);
    const position = new THREE.Vector3(...this.get("position"));

    // 2D distance
    origin.z = position.z;
    origin.x = position.x;

    return Math.round(position.distanceTo(origin) / 10) * 10;
  }

  get dotCount() {
    return this.get("exhibit")
      ?.get("hotspots")
      .reduce((sum, current) => {
        return current.level === 2 ? sum + 1 : sum;
      }, 0);
  }

  get dotImage() {
    return !this.hasDots ? "plus" : "";
  }

  hasDotCount() {
    return this.contentType === "zoom" && this.level === 1;
  }

  update() {
    this.container.dotCount = this.dotCount;
    this.container.labelPosition = this.get("labelPosition");
    this.container.labelValue = this.get("labelValue");
    this.container.content = this.get("content");
  }

  handleHotspotClick() {
    if (this.get("contentType") === "zoom") {
      experience.get("exhibition").switchLevel(this.exhibit);
    }

    if (this.get("contentType") === "animation" && !this.get("animationName")) {
      const animation = this.exhibit?.animationController[0];
      animation.play();
    }

    const animation = this.exhibit?.animationController?.find(
      ac => ac.name === this.animationName
    );
    animation?.play();
    experience.get("exhibition").trigger("hotspot-clicked", [this]);
    if (this.get("contentType") === "large") {
      experience.camera.moveCameraTarget(
        this.exhibit.secondLevel.sidebarTarget
      );
    }
  }

  handleOpenTooltip() {
    experience.get("exhibition").trigger("tooltip-opened", [this]);
  }

  createContainer() {
    const {
      position,
      level,
      labelValue,
      labelPosition,
      contentType,
      content,
    } = this;

    this.container = Object.assign(
      document.createElement("hotspot-container"),
      {
        labelValue,
        labelPosition,
        contentType,
        content,
        level,
        dotImage: this.dotImage,
        hasDotCount: this.hasDotCount,
        dotCount: this.dotCount,
        onClickHotSpot: () => this.handleHotspotClick(),
        onOpenTooltip: () => this.handleOpenTooltip(),
      }
    );
    this.container.style.background = "transparent";

    this.hotspotGroup = new THREE.Group();
    cssScene.add(this.hotspotGroup);
    this.hotspotPositionGroup = new THREE.Group();
    const hsObject = new CSS3DObject(this.container);

    this.hotspotPositionGroup.add(hsObject);
    this.hotspotGroup.name = labelValue;
    this.setPosition(this.get("position"));
    this.hotspotGroup.rotation.y = camera.currentRotationY;

    this.setRotationPoint(labelPosition);

    this.setScaling(this.get("level"));
    if (level === 2 && this.get("exhibit")) {
      this.hotspotPositionGroup.scale.set(0, 0, 0);
    }
    this.hotspotGroup.add(this.hotspotPositionGroup);
    this.hotspotGroup.rotation.y = camera.controls.getAzimuthalAngle();

    camera.on("controlsChanged", () => {
      this.hotspotGroup.rotation.y = camera.controls.getAzimuthalAngle();
    });

    if (!this.get("nearestExhibitPlace")) {
      raycaster.enablePositionHotspot(this);
    }
  }

  setRotationPoint(labeldirection) {
    const hsWidth = this.hotspotPositionGroup.children[0].element.clientWidth;
    const hotspotRotationPointLeft =
      this.hotspotPositionGroup.position.x - hsWidth / 2;

    const hotspotRotationPointRight =
      this.hotspotPositionGroup.position.x + hsWidth / 2;
    if (labeldirection === "left") {
      this.hotspotPositionGroup.position.x =
        hotspotRotationPointLeft - DOTWIDTH / 2;
    } else {
      this.hotspotPositionGroup.position.x =
        hotspotRotationPointRight + DOTWIDTH / 2;
    }
  }

  shiftLabel(labeldirection) {
    const hsWidth = this.hotspotPositionGroup.children[0].element.clientWidth;
    const hotspotRotationPointLeft = this.hotspotPositionGroup.position.x;

    const hotspotRotationPointRight = this.hotspotPositionGroup.position.x;
    if (labeldirection === "left") {
      this.hotspotPositionGroup.position.x =
        hotspotRotationPointLeft - DOTWIDTH;
    } else {
      this.hotspotPositionGroup.position.x =
        hotspotRotationPointRight + DOTWIDTH;
    }
  }

  setLabelPosition(orientation) {
    this.set("labelPosition", orientation);
    this.shiftLabel(orientation);
    this.update();
  }

  setPosition(position) {
    this.set("position", position);
    this.hotspotGroup.position.set(...position);
  }

  setScaling(level) {
    let scale;

    switch (boothTemplate.type) {
      case "standalone":
        scale = 0.27;
        break;
      case "environment":
        scale = 0.15;
        break;
      case "booth":
        scale = level === 2 ? 0.27 : 0.8;
        break;
      case "platform":
        scale = 0.5;
        break;
      default:
        scale = 0.27;
        break;
    }

    this.hotspotPositionGroup.scale.set(scale, scale, scale);
  }

  scaleDown() {
    const downScaleTween = new TWEEN.Tween(this.hotspotPositionGroup.scale)
      .to({ x: 0, y: 0, z: 0 }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  scaleUp() {
    const scaling = this.setScaling(this.get("level"));
    const upScaleTween = new TWEEN.Tween(this.hotspotPositionGroup.scale)
      .to({ x: scaling, y: scaling, z: scaling }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  set(prop, value) {
    super.set(prop, value);
    this.update();
  }

  serialize() {
    const [x, y, z] = this.get("position");

    return {
      status: "published",
      label: this.get("labelValue"),
      labelposition: this.get("labelPosition"),
      contenttype: this.get("contentType"),
      level: this.get("level"),
      content: this.get("content"),
      animationname: this.get("animationName"),
      editingurl: this.get("editingUrl"),
      nearestexhibitplace: this.get("exhibit").get("placeName"),
      positionx: x,
      positiony: y,
      positionz: z,
    };
  }

  async create() {
    const response = { type: "success", message: "" };

    const apiHotspot = await this.api.createNewHotspot(this.serialize());
    this.id = apiHotspot.id;

    await this.api.addNewHotspotToExhibit(
      this.get("id"),
      this.get("exhibit").get("id")
    );

    this.get("exhibit")
      .get("hotspots")
      .push(this);

    this.trigger("created", [response]);
  }

  async flush() {
    this.dispose();

    const exhibit = this.get("exhibit");
    let hotspots = exhibit.get("hotspots");

    const idsToDelete = [this.get("id")];

    if (this.get("level") === 1 && this.get("contentType") === "zoom") {
      hotspots.forEach(hotspot => {
        if (hotspot.get("level") === 2) {
          idsToDelete.push(hotspot.get("id"));
        }
      });
    }

    const res = await this.api.deleteHotspotsByIds(idsToDelete);

    hotspots = hotspots.filter(
      hotspot => !idsToDelete.includes(hotspot.get("id"))
    );

    exhibit.set("hotspots", hotspots);
    exhibit.updateHotspots();

    return res.status;
  }

  async persist() {
    const res = await this.api.updateHotspotById(
      this.get("id"),
      this.serialize()
    );
    await this.api.updateProjectById(
      this.get("exhibit")
        .get("project")
        .get("id"),
      {
        date_updated: new Date(),
      }
    );

    return res.status;
  }

  dispose() {
    cssScene.remove(this.hotspotGroup);
    this.container.remove();
  }
}
