const SEVEN_DAY_IN_MILLISECONDS = 7* 24 * 60 * 60 * 1000;

function setCookie(cname, cvalue) {
  const d = new Date();
  d.setTime(d.getTime() + SEVEN_DAY_IN_MILLISECONDS);
  let expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

function expireCookie(cname) {
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(cname + "=") == 0) {
      let d = new Date();
      d.setTime(d.getTime() - SEVEN_DAY_IN_MILLISECONDS);
      let expires = `expires=${d.toUTCString()}`;
      document.cookie = `${cname}=;${expires};path=/;`;
      break;
    }
  }
}

export { getCookie, setCookie, expireCookie };
