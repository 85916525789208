import { LitElement, html, css } from "lit";
import { ProviderMixin } from "lit-element-context";

import Navigo from "navigo";
import Api from "./api/api-helper";
import jwt_decode from "jwt-decode";
import { handleInvalidRequest } from "./api/helper";
import { getRouteNameByUrl } from "./services/url";
import { getCookie } from "./services/cookie";

// Styles
import shared from "./shared/styles.scss";

// Components
import "@shs/ui-marcom/src/js/modal-dialog";
import "@shs/ui-marcom/src/js/loading-animation";
import "./custom-uimc/custom-alert";
import "./custom-uimc/custom-overlay/custom-overlay";
import "./custom-uimc/custom-modal-dialog/custom-modal-dialog";

// Pages
import "./pages/project-list/project-list";
import "./pages/edit-project/edit-project";
import "./pages/create-project/create-project";
import "./pages/preview-project/preview-project";
import "./pages/user-login/user-login";
import "./pages/change-password/change-password";
import "./pages/reset-password/reset-password";

class SimplEXConfigurator extends ProviderMixin(LitElement) {
  static get properties() {
    return {
      language: { type: String, attribute: true },
      basePath: { type: String, attribute: "base-path" },
      appVersion: { type: String, attribute: "app-version" },
      deeplink: { type: String, attribute: false },
      router: { type: Object, attribute: false },
      routerView: { type: Object },
      activeTab: { type: Object, attribute: false },
      // isPreview: { type: Boolean },
      isLoading: {
        type: Boolean,
        attribute: false,
      },
      popupType: {
        type: String,
        attribute: false,
      },
      popupIndicator: {
        type: String,
        attribute: false,
      },
      popupTitle: {
        type: String,
        attribute: false,
      },
      popupContent: {
        type: Object,
        attribute: false,
      },
      popupSubmitLabel: {
        type: String,
        attribute: false,
      },
      popupSubmitCallback: {
        type: String,
        attribute: false,
      },
      popupCancelLabel: {
        type: String,
        attribute: false,
      },
      popupCancelCallback: {
        type: String,
        attribute: false,
      },
      popupIsOpen: {
        type: Boolean,
        attribute: false,
      },
      popupSize: {
        type: String,
        attribute: false,
      },
      experienceControls: {
        attribute: false,
        type: Object,
      },
      currentUser: {
        type: Object,
        attribute: false,
      },
    };
  }

  static get styles() {
    return [css(shared)];
  }

  static get provide() {
    return [
      "appVersion",
      "showAlert",
      "showModal",
      "showOverlay",
      "setLoading",
      "hidePopup",
      "popupIsOpen",
      "popupType",
      "setExperienceControls",
      "getExperienceControls",
      "currentUser",
      "activeTab",
    ];
  }

  constructor() {
    super();

    this.router = null;

    this.isPreview = false;
    this.isLoading = false;
    this.experienceControls = null;

    this.popupType = "";
    this.popupIndicator = "";
    this.popupTitle = "";
    this.popupContent = "";
    this.popupSubmitLabel = "";
    this.popupCancelLabel = "";
    this.popupSubmitCallback = () => {};
    this.popupCancelCallback = () => {};
    this.popupSize = "";
    this.popupIsOpen = false;

    this.currentUser = "";
    this.activeTab = null;

    this.showAlert = this.showAlert.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.setLoading = this.setLoading.bind(this);

    this.setExperienceControls = this.setExperienceControls.bind(this);
    this.getExperienceControls = this.getExperienceControls.bind(this);
  }

  async firstUpdated() {
    this.api = await Api.init();
    this.initializeRouter();
    this.initializeTracking();
  }

  get currentRoute() {
    return this.router?.lastRouteResolved().url;
  }

  async loginUser(user) {
    try {
      await this.api.init(user);

      if (this.deeplink) {
        const route = getRouteNameByUrl(this.deeplink);
        this.router.navigate(route);
        return;
      }

      this.router.navigate("project-list");
    } catch (error) {
      this.showAlert(
        "Login failed",
        `E-Mail or password do not match.`,
        "error"
      );
    }
  }

  get isAuth() {
    const token = getCookie("token");

    if (!token) return false;

    try {
      const decode = jwt_decode(token);
      return decode.exp > Date.now() / 1000;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  setExperienceControls(controls) {
    this.experienceControls = controls;
  }

  getExperienceControls() {
    return this.experienceControls;
  }

  async getCurrentUser() {
    if (this.token === "null") {
      this.currentUser = null;
      return;
    }
    this.currentUser = await this.api.getCurrentUserFromToken();
    this.requestUpdate("currentUser");
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  hidePopup() {
    this.popupIsOpen = false;
    this.popupType = "";
  }

  showAlert(title = "", content = "", indicator = "") {
    this.popupType = "ALERT";
    this.popupTitle = title;
    this.popupContent = content;
    this.popupIndicator = indicator;
    this.popupIsOpen = true;
  }

  showModal(
    title = "",
    content = "",
    indicator = "",
    submitLabel = "",
    cancelLabel = "",
    size = "",
    submitCallback = () => {},
    cancelCallback = () => {}
  ) {
    this.popupType = "MODAL";
    this.popupTitle = title;
    this.popupContent = content;
    this.popupIndicator = indicator;
    this.popupSubmitLabel = submitLabel;
    this.popupCancelLabel = cancelLabel;
    this.popupSize = size;
    this.popupSubmitCallback = submitCallback;
    this.popupCancelCallback = cancelCallback;
    this.popupIsOpen = true;
  }

  showOverlay(content = "", size = "") {
    this.popupType = "OVERLAY";
    this.popupContent = content;
    this.popupIsOpen = true;
    this.popupSize = size;
  }

  render() {
    return html`
      <div class="app">
        ${this.routerView}
        ${this.popupType === "MODAL"
          ? html`
              <custom-modal-dialog
                ?open=${this.popupIsOpen}
                title=${this.popupTitle}
                indicator=${this.popupIndicator}
                size=${this.popupSize}
                submit-button-label=${this.popupSubmitLabel}
                cancel-button-label=${this.popupCancelLabel}
                .onSubmit=${() => {
                  this.popupSubmitCallback();
                  this.hidePopup();
                }}
                .onCancel=${() => {
                  this.popupCancelCallback();
                  this.hidePopup();
                }}
              >
                ${this.popupContent}
              </custom-modal-dialog>
            `
          : null}
        ${this.popupType === "ALERT"
          ? html`
              <uimc-alert
                type="overlay"
                indicator=${this.popupIndicator}
                title=${this.popupTitle}
                direction="top-right"
                .onHide=${() => {
                  this.hidePopup();
                }}
                ?is-open=${this.popupIsOpen}
                auto-hide="5"
              >
                ${this.popupContent}
              </uimc-alert>
            `
          : null}
        ${this.popupType === "OVERLAY"
          ? html`
              <custom-overlay
                .onClose=${() => {
                  this.hidePopup();
                }}
                size=${this.popupSize}
                ?open=${this.popupIsOpen}
              >
                ${this.popupContent}
              </custom-overlay>
            `
          : null}
        ${this.isLoading
          ? html`
              <div
                class="position-fixed inset-0 display-flex justify-content-center align-items-center bg-color-white"
              >
                <uimc-loading-animation></uimc-loading-animation>
              </div>
            `
          : null}
      </div>
    `;
  }

  initializeRouter() {
    this.router = new Navigo(window.location.origin, true, "#");
    this.router
      .on("reset-password", () => {
        this.routerView = html`
          <reset-password
            base-path=${this.basePath}
            .router=${this.router}
          ></reset-password>
        `;
      })
      .on("change-password", () => {
        this.routerView = html`
          <change-password
            base-path=${this.basePath}
            .router=${this.router}
          ></change-password>
        `;
      })
      .on("project-list", () => {
        this.routerView = this.isAuth
          ? html`
              <project-list
                base-path=${this.basePath}
                .router=${this.router}
              ></project-list>
            `
          : null;
      })
      .on("edit-project/:id", ({ id }) => {
        this.routerView = this.isAuth
          ? html`
              <edit-project
                base-path=${this.basePath}
                project-id=${id}
                .router=${this.router}
              ></edit-project>
            `
          : null;
      })
      .on("create-project", () => {
        this.routerView = this.isAuth
          ? html`
              <create-project
                base-path=${this.basePath}
                .router=${this.router}
              ></create-project>
            `
          : null;
      })
      .on("preview-project/:id", ({ id }) => {
        this.routerView = this.isAuth
          ? html`
              <preview-project
                base-path=${this.basePath}
                project-id=${id}
                .router=${this.router}
              ></preview-project>
            `
          : null;
      })
      .on("user-login", () => {
        this.routerView = html`
          <user-login
            base-path=${this.basePath}
            .router=${this.router}
            .onSubmitLogin=${u => this.loginUser(u)}
          ></user-login>
        `;
      })
      .on("*", () =>
        this.isAuth
          ? this.router.navigate("project-list")
          : this.router.navigate("user-login")
      )
      .resolve();

    this.router.hooks({
      before: done => {
        this.redirectOnMissingToken();
        done();
      },
      after: () => {
        const { url } = this.router.lastRouteResolved();

        const title = url
          .replace("/", "")
          .split("-")
          .map(section => section[0].toUpperCase() + section.slice(1))
          .join(" ");

        window.ste_statistic.push({
          action: "feature.page",
          data: {
            feature: {
              id: `${this.localName}`,
              page: `${url}`,
              title: `Naviagted to ${title}`,
            },
          },
        });
      },
    });

    this.redirectOnMissingToken();
  }

  get isRootUrl() {
    return this.currentRoute === "" || this.currentRoute === "/";
  }

  async redirectOnMissingToken() {
    if (this.currentRoute.includes("reset-password")) return;

    if (!this.currentRoute.includes("user-login")) {
      if (!this.isAuth && !this.isRootUrl) {
        this.deeplink = window.location.href;
        handleInvalidRequest(this.router, this.showAlert);
      }

      if (!this.currentUser.id && this.isAuth) {
        this.currentUser = await this.api.getCurrentUserFromToken();
      }
    }

    if (this.currentRoute.includes("user-login")) {
      sessionStorage.setItem("token", null);
      this.currentUser = "";
    }
  }

  async initializeTracking() {
    window.ste_statistic = window.ste_statistic || [];

    const title = this.localName
      .split("-")
      .map(section => section[0].toUpperCase() + section.slice(1))
      .join(" ");

    window.ste_statistic.push({
      action: "feature.use",
      data: {
        feature: {
          id: `${this.localName}`,
          page: "/",
          title: `${title}`,
        },
      },
    });
  }
}

customElements.define("simplex-configurator", SimplEXConfigurator);
